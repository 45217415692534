import sysEnv from '@/env/env'
import { fileRequest } from '@/api'
import { loadResourcesByCache } from '@/components/base/file/libv2'

const titleName = sysEnv.title || '未配置单位名称'

function exportPdf (html) {
  const id = new Date().getTime()+ '-' + parseInt(Math.random() * 100)
  const iframe = document.createElement('iframe')
  iframe.setAttribute('id', id)
  iframe.style.display = 'none'
  document.body.appendChild(iframe)
  const style = '<link rel="stylesheet" href="/static/document/table-print.css">'
  const print = '<script>document.addEventListener("DOMContentLoaded", function () {setTimeout(function () {window.print();setTimeout(function () {window.parent.document.getElementById("' + id + '").remove();}, 1000)}, 0);});</script>'
  iframe.srcdoc = '<!DOCTYPE html><head>' + style + '</head><body>' + html + '</body> ' + print + ' </html>'
}

export async function exportRepairPdf (data) {
  console.log(data)
  const base = data.statusActionRecord.map(({ id, statusKey, statusActionLabel, remark, userName, createTime, userSignFileId }) => {
    return { id, statusKey, statusActionLabel, remark, userName, createTime, userSignFileId }
  }).sort((a, b) => a.id - b.id)

  const signFileIds = Array.from(new Set([
    ...base.map(v => v.userSignFileId),
    data.userSignFileId,
    data.orgChargeUserSignFileId
  ])).filter(v => v)
  const signFileData = signFileIds.length ? await fileRequest.getFileData({
    ids: signFileIds.join(',')
  }) : []
  const signFiles = await Promise.all(signFileData.map(async file => {
    const blob = await loadResourcesByCache({ file })
    return { id: file.id, blob }
  }))

  let totalNum = 0
  let totalPrice = data.repairJe
  const detailList = data.detailList || []
  const repairJe = detailList.length <= 1 ? data.repairJe : ''
  const items = detailList.map((item, index) => {
    totalNum++
    return `
      <tr class="border">
        <td>${index + 1}</td>
        <td>${item.assets ? item.assets.name : item.assetsName}</td>
        <td>${item.assets ? item.assets.ggxh : ''}</td>
        <td>1</td>
        <td>${repairJe ? (repairJe + '元') : ''}</td>
      </tr>
    `
  }).join('')

  const records = [
    { key: 'wait_1', 'label': "对口职能部门<br />核实情况" },
    { key: 'wait_2', 'label': "采购部门<br />分管领导意见" },
    { key: 'wait_4', 'label': "单位审批意见" }
  ].map(item => {
    const res = base.filter(v => v.statusKey === item.key).pop()
    const sign = res ? signFiles.find(v => v.id === res.userSignFileId) : null
    return `
      <tr class="border">
        <td colspan="2">${item.label}</td>
        <td colspan="7">
          ${res ? (res.remark || res.statusActionLabel) : ''}<br />
          <div style="margin-top: 20px;justify-content: flex-end;display: flex;align-items: center;">
            签字：
            <span style="display: ${sign ? 'none' : 'inline-block'};min-width: 3cm;"></span>
            <img style="display: ${sign ? 'inline-block' : 'none'};height: 1cm;" src="${sign ? sign.blob : ''}" />
            <span style="display: inline-block;min-width: 3cm;">
              ${res ? res.createTime.substr(0, 10) : ''}
            </span>
          </div>
        </td>
      </tr>
    `
  }).join('')

  const applyUserSign = signFiles.find(v => v.id === data.userSignFileId)
  const applyUser = `
    <span style="display: ${applyUserSign ? 'none' : 'inline-block'};min-width: 3cm;"></span>
    <img style="display: ${applyUserSign ? 'inline-block' : 'none'};height: 1cm;" src="${applyUserSign ? applyUserSign.blob : ''}" />
  `

  const orgChargeUserSign = signFiles.find(v => v.id === data.orgChargeUserSignFileId)
  const orgChargeUser = `
    <span style="display: ${orgChargeUserSign ? 'none' : 'inline-block'};min-width: 3cm;"></span>
    <img style="display: ${orgChargeUserSign ? 'inline-block' : 'none'};height: 1cm;" src="${orgChargeUserSign ? orgChargeUserSign.blob : ''}" />
  `

  exportPdf(`
    <table class="single">
      <tr>
        <td colspan="5" style="font-weight: bolder;font-size: 18px;line-height: 75px;">${titleName}物品、设备维保申请单</td>
      </tr>
      <tr class="noborder">
        <td style="border-bottom: 1px solid #000; text-align: left;" colspan="5">申请部门：${data.applyOrgName || ''} 申请人：${applyUser} 申请时间：${data.createTime.substr(0, 10)}</td>
      <tr/>
      <tr class="border" style="border-top: 1px solid #000;line-height: 50px;">
        <td>序号</td>
        <td>项目名称</td>
        <td>品牌及规格型号</td>
        <td>数量</td>
        <td>预估价格</td>
      </tr>
      ${items}
      <tr class="border" style="line-height: 40px;">
        <td colspan="2">合计</td>
        <td></td>
        <td>${totalNum}</td>
        <td>${totalPrice}元</td>
      </tr>
      <tr class="border">
        <td>申请<br />理由</td>
        <td colspan="2" style="text-align: left">${data.reason || ''}</td>
        <td>部门负责人<br />（签名）</td>
        <td>${orgChargeUser}</td>
      </tr>
      ${records}
      <tr class="border">
        <td colspan="5" style="text-align: left; line-height: 2;">备注：1、对口职能部门分别为：基建办、办公室、药械科、总务科等；<br />2、2000元及以上项目需通过院长办公会审议后采购，经议价小组议价后总价低于5000元可直接支出，高于或等于5000元时须经院长办公会或支委会研究后支出。</td>
      </tr>
    </table>
  `)
}