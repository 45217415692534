<template>
  <fm-modal title="维修申请" v-model="modal" :mask-closable="false" width="800" v-if="modal">
    <div class="p-detial-from">
      <div class="right">
        <!-- <fm-title titleText="采购明细"></fm-title> -->
        <fm-form label-align="left">
          <fm-form-item label="申请科室" v-if="orgList.length > 1">
            <fm-select absolute filterable :clearable="true" v-model="formData.applyOrgId">
              <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="维修资产">
            <div class="assets-list">
              <div class="assets-item" v-for="detail in formData.detailList.filter(v => !v.isDel)" :key="detail.id">
                <div v-if="detail.assets">
                  {{detail.assets.code}}
                </div>
                <div v-if="detail.assets">
                  {{detail.assets.name}}
                </div>
                <div v-if="!detail.assets">
                  {{detail.assetsName}}
                </div>
                <div v-if="!detail.assets">
                  {{detail.evaluateType === 'can_not' ? '无法估值' : (detail.jz + '元')}}
                </div>
                <a class="fmico fmico-cha close" @click="del(detail.id)"></a>
              </div>
              <div style="background: rgba(0,0, 0, 0)" class="assets-item">
                <fm-btn size="small" @click="addDetail">
                  添加维修资产
                </fm-btn>
              </div>
            </div>
          </fm-form-item>
          <fm-form-item label="关联预算">
            <fm-select absolute filterable :clearable="true" v-model="formData.budgetDetailId">
              <fm-option v-for="item in budgetDetailList" :key="item.id" :value="item.id" :label="item.code + ' ' + item.goodsName"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="预估维修金额">
            <fm-input-new v-model="formData.repairJe"></fm-input-new>
          </fm-form-item>
          <fm-form-item label="维修厂家">
            <fm-input-new v-model="formData.repairCmp"></fm-input-new>
          </fm-form-item>
          <!-- <fm-form-item label="维修内容">
            <fm-input-new type="textarea" v-model="formData.content"></fm-input-new>
          </fm-form-item> -->
          <fm-form-item label="申请理由">
            <fm-input-new type="textarea" v-model="formData.reason"></fm-input-new>
          </fm-form-item>
        </fm-form>
        <div class="model-btn">
          <fm-btn style="margin-right: 30px;" @click="formSubmit">保存</fm-btn>
          <fm-btn @click="modal = false">取消</fm-btn>
        </div>
      </div>
    </div>
    <fm-modal title="添加维修资产" :mask-closable="false" v-model="modalDetail" width="500" v-if="modalDetail">
      <fm-form label-align="left">
          <fm-form-item label="是否维修现有资产">
            <fm-select absolute filterable v-model="detailFromData.isAssetsId">
              <fm-option v-for="item in ['是', '否']" :key="item" :value="item" :label="item"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="维修资产" v-if="detailFromData.isAssetsId === '是'">
            <fm-select absolute filterable v-model="detailFromData.assetsId">
              <fm-option v-for="item in assetsList" :key="item.id" :value="item.id" :label="item.code + ' ' + (item.useOrgName || '') + ' ' + item.name"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="资产名称" v-if="detailFromData.isAssetsId === '否'">
            <fm-input-new v-model="detailFromData.assetsName"></fm-input-new>
          </fm-form-item>
          <fm-form-item label="是否无法估值" v-if="detailFromData.isAssetsId === '否'">
            <fm-switch size="small" v-model="detailFromData.canNotEvaluate">
              <span slot="on">无法估值</span>
              <span slot="off">可估值</span>
            </fm-switch>
          </fm-form-item>
          <fm-form-item label="资产价值" v-if="detailFromData.isAssetsId === '否' && !detailFromData.canNotEvaluate">
            <fm-input-new v-model="detailFromData.jz"></fm-input-new>
          </fm-form-item>
        </fm-form>
        <div class="model-btn">
          <fm-btn style="margin-right: 30px;" @click="add">添加</fm-btn>
          <fm-btn @click="modalDetail = false">取消</fm-btn>
        </div>
    </fm-modal>
  </fm-modal>
</template>

<script>
import {
  assetsRepairApplyRequest
} from '../../../api'

export default {
  props: {
    data: { type: Object, defualt: () => null },
    byAssets: { type: Boolean, defualt: false},
    orgList: { type: Array, defualt: () => [] },
    assetsList: { type: Array, defualt: () => [] },
    budgetDetailList: { type: Array, defualt: () => [] }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        let data = this.data ? JSON.parse(JSON.stringify(this.data)) : {
          detailList: []
        }
        if (!data.detailList) {
          data.detailList = []
        }
        this.formData = data
      },
      immediate: true
    }
  },
  data () {
    return {
      formData: {
        detailList: []
      },
      searchText: '',
      step: 0,
      modal: false,
      loading: false,
      modalDetail: false,
      detailFromData: {
        isAssetsId: '是',
        assetsId: null,
        assetsName: null,
        assets: null,
        jz: null
      }
    }
  },
  methods: {
    addDetail () {
      this.detailFromData = {
        isAssetsId: '是',
        assetsId: null,
        assetsName: null,
        assets: null,
        jz: null,
        canNotEvaluate: false
      }
      this.modalDetail = true
    },
    del (id) {
      this.formData.detailList = this.formData.detailList.map(v => {
        if (v.id === id) {
          v.isDel = true
        }
        return v
      })
    },
    add () {
      if (this.detailFromData.isAssetsId === '是') {
        if (!this.detailFromData.assetsId) {
          this.$notify({
            title: '系统提示',
            message: '请选择维修资产',
            type: 'info'
          })
          return
        }
        this.detailFromData.assets = this.assetsList.find(v => v.id === this.detailFromData.assetsId)
        this.detailFromData.jz = this.detailFromData.assets.jz
        this.detailFromData.assetsName = null
      } else {
        if (!this.detailFromData.assetsName) {
          this.$notify({
            title: '系统提示',
            message: '请输入物品名称',
            type: 'info'
          })
          return
        }
        if (isNaN(this.detailFromData.jz)) {
          this.$notify({
            title: '系统提示',
            message: '物品价值请输入数值',
            type: 'info'
          })
          return
        }
        if (this.detailFromData.jz != null) {
          this.detailFromData.jz = this.detailFromData.jz.replaceAll(' ', '')
          if (this.detailFromData.jz === '') {
            this.detailFromData.jz = null
          } else {
            this.detailFromData.jz = Number(this.detailFromData.jz)
          }
        }
        if (this.detailFromData.canNotEvaluate) {
          this.detailFromData.evaluateType = 'can_not'
          this.detailFromData.jz = null
        }
        this.detailFromData.assetsId = null
      }
      this.detailFromData.id = 'id_' + new Date().getTime()
      this.formData.detailList.push(this.detailFromData)
      this.modalDetail = false
    },
    async formSubmit () {
      if (this.loading) {
        return
      }
      if (!this.formData.budgetDetailId) {
        this.$notify({
          title: '系统提示',
          message: '请选择预算',
          type: 'info'
        })
        return
      }
      if (!this.formData.applyOrgId) {
        if (this.orgList.length === 1) {
          this.formData.applyOrgId = this.orgList[0].key
        } else {
          this.$notify({
            title: '系统提示',
            message: '请选择申请科室',
            type: 'info'
          })
          return
        }
      }
      if (isNaN(this.formData.repairJe)) {
        this.$notify({
          title: '系统提示',
          message: '预估维修金额请输入数值',
          type: 'info'
        })
        return
      }
      if (this.formData.repairJe != null && typeof this.formData.repairJe === 'string') {
        this.formData.repairJe = this.formData.repairJe.replaceAll(' ', '')
        if (this.formData.repairJe === '') {
          this.formData.repairJe = null
        }
      }
      if (this.formData.detailList.filter(v => !v.isDel).length === 0) {
        this.$notify({
          title: '系统提示',
          message: '请选择维修资产',
          type: 'info'
        })
        return
      }
      this.formData.detailList.forEach(v => typeof v.id === 'string' ? v.id = null : null)
      this.formData.jz = this.formData.detailList.filter(v => !v.isDel)[0].jz
      this.formData.type = this.formData.jz && this.formData.jz > 2000 ? 'norm' : 'mini'
      this.loading = true
      let data = this.formData
      if (data.id) {
        await assetsRepairApplyRequest.update(data.id, data)
      } else {
        await assetsRepairApplyRequest.add(data)
      }
      this.$emit('dataUpdate', 'add')
      this.modal = false
      this.loading = false
    }
  }
}
</script>

<style scoped lang="less">
.p-detial-from {
  display: flex;
  .center {
    max-height: 100%;
    overflow-y: auto;
    width: 380px;
    padding: 0 20px;
  }
  .left {
    flex: 1;
    border-radius: 5px;
  }
  .left-a {
    border: 1px solid #e8eaec;
    border-radius: 5px;
    flex: 1;
    display: flex;
  }
  .right {
    margin-right: 20px;
    width: 100%;
  }
  .purchase-batch-detail-list-search {
    margin-left: 10px;
  }
  .purchase-batch-detail-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    max-height: calc(100% - 80px);
    overflow-y: auto;
  }
  .purchase-batch-detail-item {
    background: #e8eaec;
    color: #515a6e;
    line-height: 28px;
    padding: 0 10px;
    border-radius: 5px;
    margin: 10px 0 0 10px;
  }
  .purchase-batch-detail-item-a {
    background: #F4628F;
    color: #fff;
  }
}
.model-btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.assets-list {
  display: flex;
  flex-wrap: wrap;
  .assets-item {
    position: relative;
    min-width: 150px;
    display: flex;
    min-height: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 0 20px;
    background: rgba(0,0, 0, 0.1);
    border-radius: 5px;
    .close {
      position: absolute;
      top: 0;
      right: 10px;
      color: #999;
    }
  }
}
</style>